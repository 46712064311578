//
// Button (.button)
//
//***********************************************************************
.button {
	background-color: $colour-button-primary;
	@include font-size(16);
	padding: 12px 16px 12px 26px;
	margin: 0;
	font-weight: bold;
	font-family: $font-body;
	color: $colour-button-txt;
	border: none;
	line-height: 1;
	display: block;
	cursor: pointer;
	overflow: visible;
	text-decoration: none;
	transition: background-color .2s ease-in-out;
	text-align: left;
	position: relative;
}
	.button:hover,
	.button:focus { background-color: lighten($colour-button-primary, 10%); color: #fff; }

	// link specific
	a.button { text-decoration: none; display: inline-block; color: #fff; }

	// Button arrow
	.button:before {
		content: "";
		@include background-arrow();
		width: 11px;
		height: 11px;
		position: absolute;
		transform: rotate(90deg) translateX(-50%);
		left: 9px;
		top: 50%;
	}

	//
	//	Button modifiers
	//

	// Full width button
	.button--wide,
	a.button--wide { display: block; width: 100%; }

	// Secondary button
	.button--secondary { background-color: $colour-button-secondary; }
		.button--secondary:hover,
		.button--secondary:focus { background-color: lighten($colour-button-secondary, 10%); color: #fff; }

	// Transparent button
	.button--reverse,
	a.button--reverse {
		background-color: $colour-button-txt;
		color: $colour-button-primary;
		border: 1px solid $colour-button-border;

		// Hover
		&:hover { background-color: rgba(0,0,0,0.05); color: $colour-button-primary; }

		// Arrow colour
		&:before { @include background-arrow($colour-button-primary); }
	}

	// Bordered button
	.button--bordered { border: 1px solid $colour-button-border; padding-top: 11px; padding-bottom: 11px; }

	// Button with arrow pointing left (Used for prev)
	.button--arrow-left {
		&:before { transform: rotate(-90deg) translateX(50%); }
	}


	// Button with arrow on the right
	.button--arrow-after {
		padding-left: 16px;
		padding-right: 26px;
		&:before { left: auto; right: 9px; }
	}

	// Button with no arrow
	.button--no-arrow {
		padding-left: 16px;
		padding-right: 16px;
		&:before { content: none; }
	}

	// Button text: light
	.button--light-text { font-weight: normal; }

	// Flex button
	.button.button--flex { display: flex; line-height: 1; align-items: center; }

	// Disabled button
	.button--disabled {
		cursor: not-allowed;
		&:hover { background-color: inherit; }
	}
