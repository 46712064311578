//
// General Rules and classes
//
//***********************************************************************/
html {
	background: $colour-background;
	font-size: 1em;
	font-family: $font-body;
	color: $colour-body;
	font-kerning: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
	text-rendering: optimizeLegibility;
	min-width: 320px;
}

body { @include font-size(16); line-height: 1.4; border-top: 10px solid $colour-primary; }

// Links
a { color: $colour-primary; text-decoration: none; }

	a:hover, a:focus, a:active { color: $colour-primary; }
	a:hover, a:active { outline: 0; }

// Headings
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
	line-height: 1.2;
	font-family: $font-heading;
	font-size: inherit;
	font-weight: bold;
	color: $colour-primary;
	letter-spacing: -1px;
}

h1, .h1 { @include font-size(54); line-height: 1; }
h2, .h2 { @include font-size(34); line-height: 1.1; }
h3, .h3 { @include font-size(32); }
h4, .h4 { @include font-size(26); }

	// Responsive sizes
	// @include bp(medium) {
	// 	h1, .h1 { @include font-size(44); line-height: 1; }
	// 	h2, .h2 { @include font-size(32); }
	// 	h3, .h3 { @include font-size(26); }
	// 	h4, .h4 { @include font-size(20); }
	// }
	// @include bp(large) {
	// 	h1, .h1 { @include font-size(54); }
	// 	h2, .h2 { @include font-size(34); }
	// 	h3, .h3 { @include font-size(32); }
	// 	h4, .h4 { @include font-size(26); }
	// }

// Text defaults
strong { font-weight: bold; }
em { font-style: italic; }

// Images
img { vertical-align: bottom; max-width: 100%; height: auto; }