//
// Tooltip (.tooltip)
//
//***********************************************************************
.tooltip {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	cursor: pointer;
	position: relative;
	flex-shrink: 0;
	align-self: flex-start;
	margin: 2px 0 0 10px;
}

	// Colour
	.tooltip--colour-purple	 	{ background-color: $purple; }
	.tooltip--colour-blue	 	{ background-color: $blue; }
	.tooltip--colour-turquoise	{ background-color: $turquoise; }
	.tooltip--colour-pink	 	{ background-color: $pink; }
	.tooltip--colour-green	 	{ background-color: $green; }
	.tooltip--colour-orange	 	{ background-color: $orange; }
	.tooltip--colour-grey	 	{ background-color: $off-black; }

	// Icon
	.tooltip__icon {
		color: $white;
		font-weight: bold;
		line-height: 1;
		@include font-size(13);
	}

	// Tooltip text
	.tooltip__text {
		display: none;
		z-index: 9999;
		background-color: $white;
		border: 1px solid rgba($colour-border, 0.4);
		box-shadow: 0 2px 4px rgba(0,0,0,0.1);
		border-radius: 4px;
		padding: 15px 20px;
		@include font-size(14);
		color: $off-black--light;
		min-width: 275px;
		position: absolute;
		bottom: calc(100% + 13px);
		right: -10px;
	}
		// Tooltip arrow
		.tooltip__text:after,
		.tooltip__text:before {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
			transform: rotate(360deg);
		}
			// Background
			.tooltip__text:after {
				right: 21px;
				top: calc(100% - 1px);
				border-width: 0 16px 9px 0;
				border-color: rgba(255,255,255,0) $white rgba(255,255,255,0) rgba(255,255,255,0);
			}
			// Border
			.tooltip__text:before {
				top: 100%;
				right: 20px;
				border-width: 0 16px 10px 0;
				border-color: rgba(255,255,255,0) rgba($colour-border, 0.5) rgba(255,255,255,0) rgba(255,255,255,0);
			}

	//
	// Hover
	//
	.tooltip:hover {
		.tooltip__text { display: block; }
	}
