//
// Block Colour feature (.colour-feature)
//
//***********************************************************************
.colour-feature {
	color: $white;
	display: flex;
	flex-direction: column;
	background-color: $green;
	padding: 20px;
	min-height: 200px;
	width: 100%;
}
	a.colour-feature:hover,
	a.colour-feature:focus { color: $white; background-color: lighten($green, 5%); }

	.colour-feature__title { @include font-size(24); color: $white; }

	.colour-feature__actions { margin-top: auto; }

		.colour-feature__action { position: relative; padding-left: 17px;}
			.colour-feature__action:before {
				@include background-arrow();
				content: "";
				position: absolute;
				width: 11px;
				height: 11px;
				left: 0;
				transform: rotate(90deg) translateX(-50%);
				top: 50%;
			}

	// Feature: Colours
	.colour-feature__grey {
		background-color: $colour-fill;

		.colour-feature__title { color: $colour-primary; }
	}