//
// Form styles (.form)
//
//***********************************************************************/
//
// Variables
//
// Spacing
$form__padding: 35px;

.form { margin-bottom: 20px; }

	// Bordered form wrapper
	.form--bordered { border: 1px solid $colour-border; }

	// Form container area
	.form__body { padding: 35px 25px; }

		.form__body--bordered { margin: 0 25px; padding: 25px 0 35px; border-top: 1px solid $colour-border; }

	// Headings (w/ capacity for tooltip)
	.form__heading { display: flex; align-items: center; margin-bottom: 10px; }
		.form__heading-text { @include font-size(20); font-weight: bold; color: $colour-body; }

	// Form text
	.form__text { color: $off-black--light; }

	// Form control (Inherits .box)
	.box.box--form__control { margin: 0; }
		.box--form__control + .box--form__control { border-top: 1px solid $white; }
		.box--form__control .button__submit-button { color: #fff; }

		// Bordered form control
		.box.box--form__control--bordered {
			border-top: 1px solid $colour-border;
			margin: 0 25px 25px;
			padding: 25px 0 0;
			width: auto;
		}

	//
	// Form actions
	//
	.form__actions { display: flex; align-items: center; }

		.form__actions-text { @include font-size(20); }

		// Control buttons
		.form__actions-ctrl {
			margin-left: auto;
			display: flex;
			justify-content: space-between;

			// Form actions button styling
			.button { opacity: 0.8; }
				.button:hover { opacity: 1; }
				.button + .button { margin-left: 10px; }
		}

	//
	// Form rows/layout
	//
	.form__row { display: flex; padding-top: $form__padding; }
	.form__row__full {display: block; padding-top: 0px;}

		.form__row + .form__row { border-top: 1px solid $colour-border; }

		// Row title/label area
		.form__row-title { width: 25%; padding-right: 40px; padding-bottom: $form__padding; }
		.form__row__full .form__row-title {width: 100%;}
		// Input area
		.form__row-input-area { width: 100%; }

		// Internal form input area
		.form__conditional { }
			.form__conditional-text { padding-bottom: $form__padding; }

		//
		//	Modifier: Inline form row
		//
		.form__row--inline {
			display: block;

			.form__row-title { width: 100%; padding: 0 0 20px; }
			.form__heading { margin: 0; }

			// Check boxes
			.form__input-checkradio { margin-bottom: $form__padding; }
				.form__input-checkradio label { padding-bottom: 0; }
		}

	// Simple form rows
	.form__row-simple {
		@include bp(medium) {
			width: 50%;
		}

		.form__input-text { width: 100%; }
	}

	// Labels
	.form__label {
		cursor: pointer;
		display: block;
		padding-bottom: 8px;
	}

	//
	// Input types
	//
	// Input type: text
	.form__input-text,
	.form__input-textarea {
		width: 75%;
		margin: 0;
		border-radius: 3px;
		@include font-size(16);
		font-family: $font-body;
		border: 1px solid $colour-border;
		padding: 5.6px 10px 5px;
		margin-bottom: $form__padding;
		&::placeholder { color: rgba($off-black, 0.5); opacity: 1; }
	}

	.form__input-textarea { height: 5.5em; }

		.form__input-text:focus,
		.form__input-textarea:focus { border: 1px solid rgba(0,0,0,0.7); }


	// Placeholder
	::placeholder { color: $colour-border; }

	// Select elements
	.form__input-select {
		border: none;
		appearance: none;
		height: 2em;
		@include font-size(13);
		font-family: $font-heading;
		background-color: #fff;
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAYBAMAAABtiDI6AAAAHlBMVEXV1dX////V1dXV1dXV1dXV1dXV1dXV1dXV1dXV1dUHiotdAAAACnRSTlMAAA5IkKytvNjmyrKbZgAAAGdJREFUGNNjEEQHDNQSMQ2Fg2CISOdMOJgBEclEiEyDiKgjRIogIkyVMIHpihARhKIiqF1wRVAlggxwRUWCcBGIIpgSQQaYoiJBJBGmSiQlggxQRUWCKCJMldMVUEUYVJwYqB+GGCIAVMlKuvq4DVsAAAAASUVORK5CYII=');
		background-repeat: no-repeat;
		background-position: right;
		padding-right: 25px;
		padding-left: 5px;
		background-size: 16px 12px;
		outline-style: solid;
		outline-color: rgba(0,0,0,0.2);
		outline-width: 1px;
		border-radius: 0;
		cursor: pointer;
	 }


	// Input type: Checkboxes/Radio buttons
	.form__input-checkradio { display: flex; flex-wrap: wrap; }
		.form__input-checkradio label {
			cursor: pointer;
			display: block;
			padding: 0 0 $form__padding;
			margin-right: 40px;
		}
			.form__input-checkradio input { margin-right: 5px; }

		// Column display
		.form__input-checkradio--column { display: block; margin-left: 30px; }


	// Forgot password
	.form__forgot-pass {
		@include font-size(12);
		text-align: right;
		float: right;
		color: $colour-secondary;
	}

	.form__suggested_action {
		margin-bottom: 20px;
		background-color: $grey--light;

		.form__suggested_action-header {
			 display: flex;
			 width: 100%;
			 border-bottom: 1px solid #fff;
			 align-items: center;
				.form__suggested_action-title,
				.form__suggested_action-commit,
				.form__suggested_action-dismiss {
					padding: 15px;
					height: 100%;
				}
				.form__suggested_action-title {
					flex: 4;
				  	border-right: 1px solid #fff;
				  	.form__tooltip {
				  		float: right;
				  	}
				}
				.form__suggested_action-commit,
				.form__suggested_action-dismiss {
					justify-content: center;
				}
				.form__suggested_action-commit {
					flex: 3;
				  	border-right: 1px solid #fff;
				}
				.form__suggested_action-dismiss {
					flex: 1;
				}
		}
		.form__suggested_action-form {
			padding: 15px;
			.form__input-text {
				margin-bottom: 15px;
			}
			.form__input-textarea {
				width: 100%;
			}
		}
		.form__suggested_action-footer {
			 display: flex;
			 fieldset {
			 	flex: 2;
			 }
		}

	}
// Conditional prompts for radio buttons; hidden by default, revealed by JS.
.js-assessment-prompt, .js-assessment-action {
	display: none;
}

.markdown__text {
	p, ul, ol {
		margin-bottom: 20px;
	}
	ul {
		list-style-type: disc;
		padding-left: 20px;
	}
	ol {
		list-style-type:decimal;
		padding-left: 20px;
	}
}

