.rich-text, .editorial {

    .blockquote__container{
        @include font-size(24);
    }

    section.embed, .embed__wrapper--responsive{
        width: 100%;
        iframe{
            width: 100%;
        }
    }

}
