//
// Chart (.chart)
//
//***********************************************************************
.chart {  }

	.chart__data { margin-bottom: 25px; }
	.chart__info { width: auto; margin: 0 -25px -25px; border-width: 1px 0 0; }

		.chart_info-title { @include font-size(30); }

	@include bp(medium) {
		.chart { display: flex; flex-direction: row; justify-content: space-between; }

			.chart__data { width: 100% / 12 * 9; padding: 0; }
			.chart__info { width: 100% / 12 * 3; margin: -25px -25px -25px 0; border-width: 0 0 0 1px; }
	}