.map-wrapper {
  height: 500px;
}

.map {
  width: 100%;
  height: 100%;
}

.map__col-aligment {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .unison-form {
    margin-bottom: 15px;

    & input {
      margin-top: 5px;
    }

    p .select2-container {
      min-width: 100%;
    }

    .select2-container--default .select2-selection--multiple {
      border-radius: 0px;
    }
  }

  .map__workplace {
    margin-bottom: 15px;
  }
}

.map__checkbox-container {
  display: flex;
  margin: 20px 0;

  label {
    margin-left: 10px; 
  }
}

.map__filter-controls {
  display: flex;
  justify-content: space-between;
}

#workplace {
  visibility: none;
}

.map__key-container {
  background-color: #F4F4F4;
  padding: 15px;

  ul li {
    display: flex;
    align-items: center;
  }

  .map__key-icon {
    border: 2px solid;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    border-color: #fff;
    margin-right: 15px;
  
    &.map__key-icon--1 {
      background-color: #1968AA;
    }
  
    &.map__key-icon--2 {
      background-color: #66AACE;
    }
  
    &.map__key-icon--3 {
      background-color: #D1E5F0;
    }
  
    &.map__key-icon--4 {
      background-color: #FDDBC8;
    }
  
    &.map__key-icon--5 {
      background-color: #EF8A64;
    }
  
    &.map__key-icon--6-and-over {
      background-color: #B2192B;
    }
  
    &.map__key-icon--activists-false {
      border-color: #9771b7;
      background-color: #421A5D;
    }
  
    &.map__key-icon--activists-true {
      border-color: #C60B1D;
      background-color: #421A5D;
    }
  }
}
