//
// Section progress (.section-progress) (Children are .box)
//
//***********************************************************************
.section-progress { }

	.box--section-progress {
		display: flex;
		padding: 15px;
		flex-direction: row;
		align-items: center;
		margin-bottom: 10px;
		border-left: 3px solid transparent;
		min-height: 65px;
	}
		// Hover/Active item
		.box--section-progress:hover,
		.box--section-progress--active { border-left-color: inherit; }

		.section-progress__title {
			font-weight: bold;
			padding-right: 15px;
			width: calc(100% - 30px);
			line-height: 1.1;
		}