//
// Site Header (.site-header)
//
//***********************************************************************/
.site-header { border-bottom: 1px solid $colour-border; }

	.site-header__inner { display: flex; justify-content: space-between; }

		.site-header__logo { padding: 10px 15px 10px 0; }
			.site-header__logo-img { max-width: 100px; padding-bottom: 5px; }
			.site-header__subtitle { @include font-size(14); }

		// Desktop only: Header contact/log in area
		.site-header__desktop { display: none; padding: 10px 0; text-align: right; }

			.site-header__logged-in { }
				.site-header__phone { @include font-size(30); font-weight: bold; line-height: 1; letter-spacing: -1px; }

			.site-header__logged-out { }

				.site-header__reg-link { @include font-size(14); color: $grey--txt; padding-bottom: 10px; }
					.site-header__reg-link a { font-weight: normal; }



		// Mobile only: Menu trigger
		.site-header__menu-trigger {
			width: 80px;
			display: flex;
			margin-right: -15px;
			padding: 15px;
			cursor: pointer;
		}
			// Active
			.site-header__menu-trigger--active,
			.site-header__menu-trigger:hover { background: $colour-fill; transform: none; }
				.site-header__menu-trigger--active .site-header__menu-arrow { transform: none; }

			// Control icons
			.site-header__menu-ctrl {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
			}
				.site-header__menu-user { width: 22px; padding-right: 5px; }
				.site-header__menu-arrow { width: 13px; transform: rotate(180deg); transition: .1s transform linear; }

	//
	// Responsive styles
	//
	@include bp(medium) {
		.site-header__inner { align-items: flex-end; padding-top: 10px; padding-bottom: 10px; }

			.site-header__logo-img { max-width: 140px; }
			.site-header__subtitle { @include font-size(22); }

			// Hide/Show contact/menu
			.site-header__desktop { display: block; }
			.site-header__menu-trigger { display: none; }
	}

	@include bp(large) {
		.site-header__logo-img { max-width: 200px; }
	}