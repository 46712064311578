//
// Box Colour Variants(.box--colour)
//
//***********************************************************************
// Colours
.box--colour {
	// Text Colours
	.box__title,
	.box__body,
	.button--reverse,
	.button--reverse:hover { color: inherit; }
}

	// Disabled box
	.box--colour-disabled {
		color: rgba($colour-body, 0.3);

		.button--reverse { }
			.button--reverse:before { background-image: none; }
	}

	.box--colour-purple {
		color: $purple;
		border-color: rgba($purple, 0.3);
		background-color: $purple--10;

		.progress-meter__ring { stroke: rgba($purple, 0.2); }
		.progress-meter__value { stroke: $purple; }
		.progress-meter__tick { fill: $purple; }

		.button--reverse:before { @include background-arrow($purple); }
		.box__submit-button { background-color: $purple; color: $white; }

		// Links
		a { color: $purple; }
		@at-root a#{&} { color: $purple; }

		// SVG
		.box--colour__svg { fill: $purple; }
	}

	.box--colour-turquoise {
		color: $turquoise;
		border-color: rgba($turquoise, 0.3);
		background-color: $turquoise--10;

		.progress-meter__ring { stroke: rgba($turquoise, 0.2); }
		.progress-meter__value { stroke: $turquoise; }
		.progress-meter__tick { fill: $turquoise; }

		.button--reverse:before { @include background-arrow($turquoise); }
		.box__submit-button { background-color: $turquoise; color: $white; }

		// Links
		a { color: $turquoise; }
		@at-root a#{&} { color: $turquoise; }

		// SVG
		.box--colour__svg { fill: $turquoise; }
	}

	.box--colour-blue {
		color: $blue;
		border-color: rgba($blue, 0.3);
		background-color: $blue--10;

		.progress-meter__ring { stroke: rgba($blue, 0.2); }
		.progress-meter__value { stroke: $blue; }
		.progress-meter__tick { fill: $blue; }

		.button--reverse:before { @include background-arrow($blue); }
		.box__submit-button { background-color: $blue; color: $white; }

		// Links
		a { color: $blue; }
		@at-root a#{&} { color: $blue; }

		// SVG
		.box--colour__svg { fill: $blue; }
	}

	.box--colour-pink {
		color: $pink;
		border-color: rgba($pink, 0.3);
		background-color: $pink--10;

		.progress-meter__ring { stroke: rgba($pink, 0.2); }
		.progress-meter__value { stroke: $pink; }
		.progress-meter__tick { fill: $pink; }

		.button--reverse:before { @include background-arrow($pink); }
		.box__submit-button { background-color: $pink; color: $white; }

		// Links
		a { color: $pink; }
		@at-root a#{&} { color: $pink; }

		// SVG
		.box--colour__svg { fill: $pink; }
	}

	.box--colour-green {
		color: $green;
		border-color: rgba($green, 0.3);
		background-color: $green--10;

		.progress-meter__ring { stroke: rgba($green, 0.2); }
		.progress-meter__value { stroke: $green; }
		.progress-meter__tick { fill: $green; }

		.button--reverse:before { @include background-arrow($green); }
		.box__submit-button { background-color: $green; color: $white; }

		// Links
		a { color: $green; }
		@at-root a#{&} { color: $green; }

		// SVG
		.box--colour__svg { fill: $green; }
	}

	.box--colour-orange {
		color: $orange;
		border-color: rgba($orange, 0.3);
		background-color: $orange--10;

		.progress-meter__ring { stroke: rgba($orange, 0.2); }
		.progress-meter__value { stroke: $orange; }
		.progress-meter__tick { fill: $orange; }

		.button--reverse:before { @include background-arrow($orange); }
		.box__submit-button { background-color: $orange; color: $white; }

		// Links
		a { color: $orange; }
		@at-root a#{&} { color: $orange; }

		// SVG
		.box--colour__svg { fill: $orange; }
	}

	.box--colour-red {
		color: $red;
		border-color: rgba($red, 0.3);
		background-color: $red--10;

		.progress-meter__ring { stroke: rgba($red, 0.2); }
		.progress-meter__value { stroke: $red; }
		.progress-meter__tick { fill: $red; }

		.button--reverse:before { @include background-arrow($red); }
		.box__submit-button { background-color: $red; color: $white; }

		// Links
		a { color: $red; }
		@at-root a#{&} { color: $red; }

		// SVG
		.box--colour__svg { fill: $red; }
	}

	.box--colour-transparent { background-color: transparent; }
