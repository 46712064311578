//
// Photo feature (.photo-feature)
//
//***********************************************************************
.photo-feature {
	color: $white;
	display: flex;
	flex-direction: column;
	background-color: $blue;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 300px;
	width: 100%;
}
	.photo-feature:hover,
	.photo-feature:focus {
		color: $white;
		.photo-feature__body { background-color: $blue; }
	}

	.photo-feature__body { padding: 20px; margin-top: auto; background-color: rgba($blue, 0.8); }

		.photo-feature__title { @include font-size(24); color: $white; margin-bottom: 15px; }

		.photo-feature__action { position: relative; padding-left: 17px;}
			.photo-feature__action:before {
				content: "";
				@include background-arrow();
				position: absolute;
				width: 11px;
				height: 11px;
				left: 0;
				transform: rotate(90deg) translateX(-50%);
				top: 50%;
			}