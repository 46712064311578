//
// Branch list (.branch-list)
//
//***********************************************************************
.branch-list { }

	.branch-list li {
		border: 1px solid $colour-border;
		margin-left: -1px;
		margin-top: -1px;
	}
		.branch-list li:hover { background: $colour-fill; }

		.branch-list li a { display: block; padding: 30px; }

	// Responsive styles
	@include bp(medium) {
		.branch-list { display: flex; flex-wrap: wrap; }
			.branch-list li { width: calc(100%/3); }
	}