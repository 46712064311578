//
// Page header (.page-header)
//
//***********************************************************************
.page-header { margin-bottom: 50px; }

	.page-header__meta { color: $colour-primary; }

	.page-header__img { display: block; margin-bottom: 25px; }

	.page-header__title { margin-bottom: 16px; }

	.page-header__intro { }

	.page-header__actions { padding-top: 16px; }

