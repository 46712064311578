//
// Site variables
//
//***********************************************************************/

//
// Fonts
//
$helvetica-neue: "Neue Helvetica W01", "Helvetica Neue", Helvetica, Arial, sans-serif;

// Fonts: Assignments
$font-body: $helvetica-neue;
$font-heading: $helvetica-neue;

//
// Colours
//
$white: rgb(255,255,255);
$off-black: rgb(51,51,51);
$off-black--light: rgba($off-black, 0.8);
$off-white: rgb(253,253,253);

$unison-purple: rgb(67,22,94);
$unison-blue: rgb(26,101,156);

$purple: rgb(106,61,133);
$turquoise: rgb(41,158,187);
$blue: rgb(34,113,164);
$pink: rgb(225,16,110);
$green: rgb(114,163,64);
$orange: rgb(233,115,0);
$red: rgb(229,50,50);

$grey: rgb(210,210,210);
$grey--alt: rgb(233,233,233);
$grey--light: rgb(244,244,244);
$grey--txt: rgb(100,100,100);

// Colours: Assignments
$colour-background: $white;
$colour-primary: $unison-purple;
$colour-secondary: $unison-blue;
$colour-body: $off-black;
$colour-border: $grey;
$colour-fill: $grey--alt;

// Alerts
$colour-alert--info: $orange;
$colour-alert--error: $pink;
$colour-alert--success: $green;

// Colours: Buttons
$colour-button-primary: $unison-purple;
$colour-button-secondary: $unison-blue;
$colour-button-txt: $white;
$colour-button-border: rgba($grey--txt, 0.3);

// Colours: Opaque
$purple--10: rgba($purple, 0.1);
$turquoise--10: rgba($turquoise, 0.1);
$blue--10: rgba($blue, 0.1);
$pink--10: rgba($pink, 0.1);
$green--10: rgba($green, 0.1);
$orange--10: rgba($orange, 0.1);
$red--10: rgba($red, 0.1);
