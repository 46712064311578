//
//  Font Size mixin
//	Font size in rem, stated in px (pixelrem will post-process the px fallback)
//***********************************************************************/
@mixin font-size($sizeValue) {
	font-size: ($sizeValue / 16) + rem;
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
