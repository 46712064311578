//
// 	Site Grid (.site-row, .site-col)
//	Simple grid system for the site
//***********************************************************************/
.site-row { margin-bottom: 20px; }

	.site-col { margin-bottom: 50px; }

// Add the grid for non-mobile devices
@include bp(medium) {
	.site-row { display: flex; margin: 0 -10px 20px; }

		.site-col { margin: 0; }

		// Create site-col widths (e.g. .site-col3)
		$grid-columns: 12;
		@for $i from 1 through $grid-columns {

			.site-col--#{$i} {
				width: 100% / $grid-columns * $i;
				padding: 0 10px;
			}
		}
}