.tabs {
    display: flex;
    height: 45px;
    margin: 0px;
    padding: 0px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid $grey;
    border-bottom: 0px none;
    border-left: 0px none;
    .tabs__tab {
        flex: 2;
        height: 100%;
        padding: 10px;
        border-right: 0px none;
        text-align: center;
        border-left: 1px solid $grey;
        &:hover {
            border-bottom: 4px solid $grey--alt;
        }

    }
    .tabs__tab--active {
        border-bottom: 4px solid;
        &:hover {
            border-bottom-color: inherit;
        }
    }
}
