.financial-plan-form {
    display: flex;
    flex-direction: column;

    .financial-plan__save-button {
        align-self: flex-end;
    }

    .table-container {
        margin: 30px 0;
    }
}

.financial-plan__table-heading {
    background-color: $grey--alt;
    padding: 15px;
    border-radius: 3px 3px 0 0;
    border-top: 1px solid $grey;
    border-left: 1px solid $grey;
    border-right: 1px solid $grey;

    h2 {
        font-weight: normal;
    }
}

.financial-plan__table {
    width: 100%; 
    border-collapse: collapse;

    th { 
        font-weight: 600; 
    }

    td, th { 
        padding: 15px; 
        border: 1px solid #ccc; 
        text-align: left; 
    }

    .financial-plan__cell-title {
        display: none;
    }

    .financial-plan__negative {
        color: red;
    }

    .financial-plan__unavailable {
        color: $grey;
    }

    .financial-plan__total-row {
        background-color: $grey--alt;
        font-weight: 600;

        .total {
            text-transform: uppercase;
            color: $unison-purple;
        }
    }

    .financial-plan__disabled {
        background-color: $grey--alt;
    }

    .financial-plan__proposed-form-field {
        padding: 0 0 0 15px;

        input {
            display: inline-block;
            min-height: 59px;
            width: calc(100% - 20px);
            border: 1px solid transparent;
            padding: 0 10px;
            &:focus {
                outline: none;
            }
        }
        @include placeholder {
            color: #000000;
            opacity: 0.54;
        }
    }
    .financial-plan__proposed-form-field.is-focused {
        outline: 1px solid $unison-purple;
        border-color: #fff;
    }
}

@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
        display: block;
        border: 0;
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

    .financial-plan__table {
        border: 0;

        td, th { 
            border: 1px solid #ccc; 
            border-bottom: 0; 

            &:last-of-type {
                border-bottom: 1px solid #ccc;
            }
        }

        .financial-plan__field {
            background-color: $grey--alt;
            font-weight: 600;
        }

        .financial-plan__cell-title {
            display: block;
            font-weight: 600;
        }

        .financial-plan__tfoot {
            display: inline-block;
            width: 100%;
        }
    }

}
