//
// Box list (.box-list)
//
//***********************************************************************
.box-list {
	margin: 30px 0;

	@include bp(medium) {
		display: flex;
		flex-wrap: wrap;
		margin: 30px -10px;

		// Wrapper elements
		.box { margin: 10px; }
	}

}
	// 3 in a row
	.box-list--3-up .box {
		width: 100%;

		@include bp(medium) {
			width: calc(100%/3 - 20px);
		}
	}

	// Wide box (Grow the flex item to take up available space)
	.box-list .box-list__box--wide { flex-grow: 1; }