//
// Notification bar (.notification-bar)
//
//***********************************************************************
.notification-bar {
	text-align: center;
	margin-bottom: $form__padding;
	color: $off-black--light;
	padding: 15px;
	@include font-size(14);
	position: fixed;
	width: 100%;
	background-color: $colour-fill;
	top: 0;
	left: 0;

	a { font-weight: bold; }
}

	// Colours
	.notification-bar--error {
		color: $colour-alert--error;
		background-color: lighten($colour-alert--error, 48%);
	}

	.notification-bar--info {
		color: $colour-alert--info;
		background-color: lighten($colour-alert--info, 48%);
	}

	.notification-bar--success {
		color: $colour-alert--success;
		background-color: lighten($colour-alert--success, 48%);
	}