.chevron::after {
    border-style: solid;
    border-width: 0.2em 0.2em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    left: 0.45em;
    position: relative;
    top: 0em;
    transform: rotate(-45deg);
    vertical-align: middle;
    width: 0.5em;
}

.chevron-right:after {
    left: 0;
    transform: rotate(45deg);
}

.chevron-down:after {
    top: 0;
    transform: rotate(135deg);
}

.chevron-left:after {
    left: 0.25em;
    transform: rotate(-135deg);
}
