//
// Action plan detail page (.action-plan)
//
//***********************************************************************
.action-plan { }

	.action-plan__row {
		margin: 0 25px;
		padding: 35px 0 19px;
		border-top: 1px solid $colour-border;
		display: flex;
	}
		// List count
		.action-plan__count {
			font-size: 18px;
			font-weight: bold;
			line-height: 1.4;
			padding-right: 10px;
			flex-basis: 30px;
		}
		// Plan content area
		.action-plan__content { width: 100%; }

			// Plan row title
			.action-plan__row-title {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				margin-bottom: 35px;
			}
				.action-plan__title-text {
					font-size: 18px;
					color: $colour-body;
					margin-right: 100px;
					line-height: 1.4;
				}
				.action-plan__title-control { justify-content: space-between; }
					.action-plan__title-control-text { padding-right: 10px; }
					.action-plan__title-control-icon { height: 14px; }

			// Plan headings
			.action-plan .action-plan__heading { font-size: 16px; color: $colour-body; margin: 35px 0 5px; }


.action-plan-archive {
	.plan-content {
		display: none;
	}
	.action-plan {
		border-bottom: 1px solid $grey--alt;
		.status {
			font-weight: bold;
		}
	}
	.plan-content .editorial {
    	padding: 0 30px;
	}
	.options {
		text-align: right;
		padding: 0 0 15px;
	}
}

.action-plan-archive__header {
	color: inherit;
	display: flex;
	width:	 100%;
	padding: 15px 30px;
	color: #000;
	.h3, .h4 {
		color: #000;
		font-weight: normal;
	}
	.title {
		flex: 8;
		color: #000;
	}
	.status {
		flex: 2;
		text-align: right;
		&.status--open {
			color: $pink;
		}
		&.status--completed {
			color: $green;
		}
	}
}

