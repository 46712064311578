//
// 	Progress meter (.progress-meter)
//	https://codepen.io/xgad/post/svg-radial-progress-meters
//***********************************************************************
// Progress meter circumference
// Width of 120, Stroke of 12
// (120 / 2) - (12 / 2) = 54
// Radius of 54
// 2 * π * 54 ≈ 339.292
$progress-meter-circum: 339.292;

svg.progress-meter {
	transform: rotate(-90deg);
	width: 30px;
	height: 30px;
	overflow: visible;
	display: block;
}

	// Hide tick by default
	.progress-meter__tick { display: none; }

	.progress-meter__ring,
	.progress-meter__value { fill: none; }

	.progress-meter__ring { stroke: rgba($purple, 0.2); }

	.progress-meter__value { stroke: $purple; stroke-dashoffset: $progress-meter-circum; }
		// Progress values, from 10 -- 90
		@for $i from 1 through 9 {
			$circum_fraction: $i / 10;
			.progress-meter--#{$i}0 .progress-meter__value {
				stroke-dashoffset: $progress-meter-circum * (1 - $circum_fraction);
			}
		}
		// Complete
		.progress-meter--100 {
			.progress-meter__value { stroke-dashoffset: 0; }
			// Show tick
			.progress-meter__tick { display: block;}
		}