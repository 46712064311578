//
// Alert message (.alert)
// Inherit classes: box box--bordered box--rounded
//***********************************************************************
.alert {
	text-align: center;
	margin-bottom: $form__padding;
	color: $off-black--light;
	padding: 15px;

	.alert__heading {
		@include font-size(20);
		color: $off-black--light;
		font-weight: normal;
		letter-spacing: normal;
		margin-bottom: 12px;
	}

	.alert__body { @include font-size(14); }
		.alert__body a { font-weight: bold; }

	.alert__icon { margin-bottom: 10px; }

}

	// Colours
	.alert--error {
		color: $colour-alert--error;
		background-color: rgba($colour-alert--error, 0.1);
		.alert__heading { color: $colour-alert--error; }
	}

	.alert--info {
		color: $colour-alert--info;
		background-color: rgba($colour-alert--info, 0.1);
		.alert__heading { color: $colour-alert--info; }
	}

	.alert--success {
		color: $colour-alert--success;
		background-color: rgba($colour-alert--success, 0.1);
		.alert__heading { color: $colour-alert--success; }
	}