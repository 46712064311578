//
// Site Wrapper (.site-wrapper)
//
//***********************************************************************
// Because certain things span full width (borders etc) we need an inner wrap class
.site-wrapper { max-width: 1280px; padding: 0 15px; margin: 0 auto; }

	// make gutter wider on desktop
	@include bp(large) { .site-wrapper { padding: 0 30px; } }

	.site-wrapper--no-padding { padding: 0; }
	.site-wrapper--body-text { max-width: 690px; }