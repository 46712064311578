//
// Box (.box)
//
//***********************************************************************
.box {
	background: $colour-fill;
	padding: 25px;
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
	width: 100%;
}

	.box__title { @include font-size(34); margin-bottom: 12px; }
		.box__title--normal-weight { font-weight: 300; }
		.box__title--small { @include font-size(24); letter-spacing: -0.5px; }

	.box__body { }
		// Smaller font size
		.box__body--small p { @include font-size(14); }

	// Box actions
	.box__actions { margin-top: auto; }

		.box__actions .button + .button { margin-left: 6px; }

		// Transparent full width variant
		.box__actions--transparent { }
			.box__actions--transparent__button-wrap {
				background-color: $white;
				margin: 25px -25px -25px -25px;
				display: flex;
			}
				// Padding for buttons
				.box__actions--transparent .button { margin: 6px 0 0; }

	// Form status progress
	.box__form-status { color: $off-black--light; margin-bottom: 10px; }
		.box__form-status-value { color: $colour-primary; font-weight: bold; text-transform: uppercase; }

	// Progress indicator
	.box__progress {
		margin-bottom: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 30px;
	}
		.box__progress-amount { }
		.box__progress-indicator { }

	//
	//	Style variants
	//
	.box--secondary {
		.box__title { color: $colour-secondary; }
	}

	.box--reduced-padding { padding: 15px; }

	.box--bordered { border-width: 1px; border-style: solid; }

	.box--rounded { border-radius: 3px; }

	// Layout variants
	.box--layout-row { flex-direction: row; align-items: center; justify-content: space-between; }
