//
// SIte Navigation (.nav-site)
//
//***********************************************************************/
.nav-site {
	display: none;
	background: $colour-fill;
	border-bottom: 1px solid $colour-border;
	font-weight: 300;
	min-height: 45px;
	@include bp(medium) {
		display: block;
	}
}
	// Hide the site nav controls for Desktop users logged out
	.nav-site.nav-site--logged-out {
		@include bp(medium) {
			display: none;
		}
	};

	// Mobile show/hide with Javascript
	.nav-site--visible { display: block; }

	// Wrapper (inherits from .site-wrapper)
	.nav-site__wrap {
		min-height: 45px;
		@include bp(medium) {
			display: flex; justify-content: space-between;
		}
	}

		// Site links
		.nav-site__links {
			border-bottom: 2px solid $colour-border;
			padding-bottom: 10px;

			@include bp(medium) {
				display: flex;
				padding-bottom: 0;
				border: none;
			}
		}

			.nav-site__links li {
				border-bottom: 4px solid transparent;
				line-height: 1;
				transition: .25s border linear;

				@include bp(medium) {
					margin-right: 30px;
				}
			}
				.nav-site__links li a,
				.nav-site__links li span { display: block; height: 100%; padding: 15px 0 11px; }

				// Hover and active states
				.nav-site__links li:hover,
				.nav-site__links li.nav-site__active { border-bottom: 4px solid $colour-primary; }

				.nav-site__links li.nav-site__active { font-weight: bold; }


		// Site control
		.nav-site__ctrl {
			display: flex;
			align-items: center;
			border-bottom: 4px solid transparent;
			transition: .25s border linear;
		}
			@include bp(medium) {
				.nav-site__ctrl:hover { border-bottom: 4px solid $colour-primary; }
			}

			// Control icon
			.nav-site__ctrl-menu {
				display: none;
				align-items: center;
				cursor: pointer;
				padding: 0 3px;
				height: 100%;
				@include bp(medium) {
					display: flex;
				}
			}
				.nav-site__ctrl-user { width: 16px; margin-right: 6px; }
				.nav-site__ctrl-arrow { transform: rotate(180deg); }

		//
		//	Dropdown menu
		//
		// Wrapper
		.nav-site__dropdown { position: relative; }
			.nav-site__dropdown:hover .nav-site__dropdown-menu {
				@include bp(medium) {
					visibility: visible;
					transition-delay: 0s;
				}
			}

			// Menu
			.nav-site__dropdown-menu {
				line-height: 1;
				width: 100%;
				padding: 10px 0;

				@include bp(medium) {
					background: $white;
					z-index: 100;
					min-width: 150px;
					padding: 10px 20px;
					border: 1px solid $colour-border;
					visibility: hidden;
					transition: .2s .25s;
					position: absolute;
					top: 100%;
					right: 0;
					margin-top: 4px;
				}
			}
				.nav-site__dropdown-menu li { padding: 10px 0; }
					.nav-site__dropdown-menu li a { display: block; }
						.nav-site__dropdown-menu li a:hover { text-decoration: underline; }
